import { useHistory, useParams } from 'react-router-dom'
import { LoadingSpinner } from '../components/Loading'
import { ApiError } from '../components/ApiError'
import { useApi, apiRoute } from '../api'
import { useEffect } from 'react'

export default function NextPaper() {
  const { projectId } = useParams()
  const history = useHistory()
  const { loading, error, refresh, data } = useApi(
    ...apiRoute.papers.getNext(projectId)
  )
  useEffect(() => {
    if (!loading && data != null)
      history.replace(`/projects/${projectId}/papers/${data.id}`)
  }, [history, loading, data, projectId])
  if (error) {
    return <ApiError error={error} refresh={refresh} />
  }
  return (
    <div className="mx-auto d-flex justify-content-center">
      <LoadingSpinner />
    </div>
  )
}
