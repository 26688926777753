import React from 'react'
import _ from 'lodash'
import './Tags.scss'

export const mapTags = tags => _.keyBy(tags, 'id')

export const renderTags = (tagIds, tagsById) => {
  return tagIds.map(id => (
    <span
      className="badge badge-pill rounded-pill badge-primary-light mr-1"
      key={id}
      style={{
        maxWidth: '6em',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}
      title={tagsById[id].value}
    >
      {tagsById[id].value}
    </span>
  ))
}

const SingleTag = ({ value, id, active, setTag }) => (
  <button
    className={`selectical-tag btn btn-sm rounded-pill mr-1 mb- ${
      active ? 'btn-primary' : 'btn-primary-light'
    }`}
    tabIndex="1"
    onClick={event => {
      event.preventDefault()
      event.stopPropagation()
      return setTag(id)
    }}
  >
    {active ? (
      <i
        className="material-icons mr-1"
        style={{ fontSize: '.8rem', transform: 'translateY(2px)' }}
      >
        check
      </i>
    ) : null}
    {value}
  </button>
)

export default function Tags({ currentTags, allTags, setTag }) {
  const current = new Set(currentTags)
  return allTags.map(tag => (
    <SingleTag
      key={tag.id}
      value={tag.value}
      id={tag.id}
      active={current.has(tag.id)}
      setTag={setTag}
    />
  ))
}
