import React, { useState } from 'react'
import _ from 'lodash'
import { Link, useParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Papers } from '../components/ProjectPapers'
import { LabelNextButton } from '../components/LabelNextButton'
import { PaperFilter } from '../components/PaperFilter'
import { LoadingSpinner } from '../components/Loading'
import { NL, EN } from '../components/Language'
import { mapTags, renderTags } from '../components/Tags'
import { useApi, apiRoute, withToken, saveAs, fixBody } from '../api'
import { ApiError } from '../components/ApiError'
import { apiError } from '../components/Notifications'

const percentage = (k = 0, n) => {
  if (n !== 0) return String(Math.round((k / n) * 1000) / 10) + ' %'
  return '- %'
}

const LabelCounts = ({
  positive = 0,
  negative = 0,
  doubt = 0,
  numPapers,
  ...rest
} = {}) => {
  const i = icon => (
    <i
      className="material-icons"
      style={{ fontSize: '1rem', transform: 'translateY(3px)' }}
    >
      {icon}
    </i>
  )

  return (
    <React.Fragment>
      <span className={`text-success`}>
        {i('check_circle')} {positive}
      </span>
      <span className={`text-warning ml-2`}>
        {i('help')} {doubt}
      </span>
      <span className={`text-danger ml-2`}>
        {i('cancel')} {negative}
      </span>
    </React.Fragment>
  )
}

export default function ProjectDetail() {
  const { getAccessTokenSilently } = useAuth0()
  const { projectId } = useParams()
  const [counts, setNumPapers] = useState({})
  const { loading, error, refresh, data: project } = useApi(
    ...apiRoute.projects.getById(projectId)
  )
  if (error) {
    return <ApiError error={error} refresh={refresh} />
  }
  if (loading) {
    return (
      <div className="mx-auto d-flex justify-content-center">
        <LoadingSpinner />
      </div>
    )
  }
  const { numPapers, numOffline } = counts
  const reviewable = numOffline > numPapers / 2 && project.status === 'finished'
  const reviewUrl = `/projects/${projectId}/review`
  const exportPapers = filetype => async () => {
    const [url, options] = apiRoute.projects.export(projectId, filetype)
    try {
      const token = await getAccessTokenSilently()
      const response = await fetch(url, fixBody(withToken(options, token)))
      if (response.ok) {
        await response.blob().then(saveAs(`${project.name}.${filetype}`))
      } else {
        throw new Error(response.statusText)
      }
    } catch (error) {
      apiError({ error, url, options })
    }
  }
  return (
    <React.Fragment>
      <div className="container">
        <div className="d-flex">
          <Link className="text-muted small mb-3 d-inline-block" to="/">
            <EN>← Back to all projects</EN>
            <NL>← Terug naar alle projecten</NL>
          </Link>
          <div
            className="ml-auto small text-muted btn btn-sm btn-light pb-0"
            onClick={exportPapers('ris')}
          >
            <i className="material-icons" style={{ fontSize: '1rem' }}>
              save_alt
            </i>
            <span>RIS</span>
          </div>
          <div
            className="ml-3 small text-muted btn btn-sm btn-light pb-0"
            onClick={exportPapers('csv')}
          >
            <i className="material-icons" style={{ fontSize: '1rem' }}>
              save_alt
            </i>
            <span>CSV</span>
          </div>
        </div>
        <div className="d-flex align-items-center mb-3">
          <h1 className="mr-4">{project.name}</h1>
          {project.status !== 'finished' ? (
            <LabelNextButton
              project={project}
              disabled={project.status !== 'ready'}
            >
              <EN>Label papers →</EN>
              <NL>Papers labelen →</NL>
            </LabelNextButton>
          ) : null}
          {reviewable ? (
            <Link
              to={reviewUrl}
              className="btn btn btn-primary-light rounded-pill font-weight-bold text-primary"
            >
              <EN>Review →</EN>
              <NL>Reviewen →</NL>
            </Link>
          ) : null}
        </div>
        <div className="d-flex flex-flow-row align-items-stretch small">
          {numPapers != null ? (
            <React.Fragment>
              <div>
                <span className="text-primary">
                  <EN>Number of papers:</EN>
                  <NL>Aantal papers:</NL>
                </span>
                <br />
                {numPapers}
              </div>
              <div className="mx-4 border-left border-primary-light" />
              <div>
                <span className="text-primary">
                  <EN>Manually labeled:</EN>
                  <NL>Handmatig gelabeld:</NL>
                </span>
                <br />
                <LabelCounts {...counts} total={numPapers} />
              </div>
              <div className="mx-4 border-left border-primary-light" />
            </React.Fragment>
          ) : null}
          <div>
            <span className="text-primary">
              <EN>Status:</EN>
              <NL>Status:</NL>
            </span>
            <br />
            {_.startCase(project.status)}
          </div>
          <div className="mx-4 border-left border-primary-light" />
          <div>
            <span className="text-primary">
              <EN>Created by:</EN>
              <NL>Aangemaakt door:</NL>
            </span>
            <br />
            {project.user_username}
          </div>
          <div className="mx-4 border-left border-primary-light" />
          <div>
            <span className="text-primary">
              <EN>Available tags:</EN>
              <NL>Beschikbare tags:</NL>
            </span>
            <br />
            {renderTags(_.map(project.tags, 'id'), mapTags(project.tags))}
          </div>
        </div>
        <div className="my-3" />
      </div>
      <div className="container">
        <PaperFilter
          projectTags={project.tags}
          projectStatus={project.status}
        />
      </div>
      <div className="container-fluid mt-4">
        <Papers
          projectId={projectId}
          tags={mapTags(project.tags)}
          setNumPapers={setNumPapers}
        />
      </div>
    </React.Fragment>
  )
}
