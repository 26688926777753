import React, { useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import './Labels.scss'

const SmallLabel = ({ label, displayLabel, current, icon, setLabel }) => (
  <span
    className={`label small-label ${label} ${
      current === label ? 'active' : ''
    } ${setLabel == null ? 'disabled' : ''}`}
    onClick={event => {
      if (setLabel == null) return
      event.preventDefault()
      event.stopPropagation()
      if (label === current) {
        return setLabel(null)
      } else {
        return setLabel(label)
      }
    }}
    style={setLabel != null ? { cursor: 'pointer' } : {}}
    tabIndex="2"
    title={displayLabel}
  >
    <i className="material-icons" style={{ fontSize: '1.15rem' }}>
      {icon}
    </i>
  </span>
)

export const LargeLabel = React.forwardRef(
  ({ label, displayLabel, current, icon, setLabel }, ref) => (
    <div
      className={`col d-flex justify-content-center my-3 label ${label} ${
        current === label ? 'active' : ''
      }`}
      style={{ flexBasis: 'auto' }}
    >
      <button
        className={`btn d-flex align-items-center large-label p-3 rounded-pill justify-content-center ${
          setLabel == null ? 'disabled' : ''
        }`}
        style={{
          flexGrow: 1,
          maxWidth: '220px',
          cursor: setLabel != null ? 'pointer' : 'default'
        }}
        ref={ref}
        disabled={setLabel == null}
        onClick={event => {
          if (setLabel == null) return
          event.preventDefault()
          event.stopPropagation()
          if (label === current) {
            return setLabel(null)
          } else {
            return setLabel(label)
          }
        }}
      >
        <i className="material-icons mr-2">{icon}</i>
        {displayLabel}
      </button>
    </div>
  )
)

export default function Labels({ label, setLabel, size = 'small' }) {
  // The options:
  // - 'positive'
  // - 'doubt'
  // - 'negative'
  // - null
  const location = useLocation()
  const firstLabel = useRef()
  useEffect(() => {
    if (firstLabel.current != null) firstLabel.current.focus()
  }, [location])

  if (size === 'small') {
    return (
      <div className="d-flex justify-content-between">
        <SmallLabel
          label="positive"
          current={label}
          setLabel={setLabel}
          icon="check_circle"
        />
        <SmallLabel
          label="doubt"
          current={label}
          setLabel={setLabel}
          icon="help"
        />
        <SmallLabel
          label="negative"
          current={label}
          setLabel={setLabel}
          icon="cancel"
        />
      </div>
    )
  }

  return (
    <div
      style={{ minWidth: '60px' }}
      className={`d-flex justify-content-between align-items-stretch ${
        size === 'large' ? 'flex-column' : ''
      }`}
    >
      <LargeLabel
        ref={firstLabel}
        label="positive"
        displayLabel="Include"
        current={label}
        setLabel={setLabel}
        icon="check_circle"
      />
      <LargeLabel
        label="doubt"
        displayLabel="Doubt"
        current={label}
        setLabel={setLabel}
        icon="help"
      />
      <LargeLabel
        label="negative"
        displayLabel="Exclude"
        current={label}
        setLabel={setLabel}
        icon="cancel"
      />
    </div>
  )
}
