import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Papers } from '../components/ProjectPapers'
import { PaperFilter } from '../components/PaperFilter'
import { LoadingSpinner } from '../components/Loading'
import { NL, EN } from '../components/Language'
import { mapTags } from '../components/Tags'
import { apiRoute, useApi } from '../api'
import { ApiError } from '../components/ApiError'

const ReviewLabelCounts = ({
  positive = 0,
  negative = 0,
  doubt = 0,
  offline_positive = 0,
  offline_negative = 0,
  offline_doubt = 0,
  ...rest
} = {}) => {
  const i = icon => (
    <i
      className="material-icons"
      style={{ fontSize: '1rem', transform: 'translateY(3px)' }}
    >
      {icon}
    </i>
  )
  return (
    <table className="table" style={{ width: 'auto' }}>
      <thead>
        <tr>
          <th scope="col" />
          <th scope="col">
            <EN>Labeled here</EN>
            <NL>Hier gelabeled</NL>
          </th>
          <th scope="col">
            <EN>Labeled elsewhere</EN>
            <NL>Elders gelabeled</NL>
          </th>
          <th scope="col">
            <EN>Difference</EN>
            <NL>Verschil</NL>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row" className="text-success">
            {i('check_circle')} Include
          </th>
          <td>{positive}</td>
          <td>{offline_positive}</td>
          <td className="font-weight-bold">
            {Math.abs(positive - offline_positive)}
          </td>
        </tr>
        <tr>
          <th scope="row" className="text-warning">
            {i('help')} Doubt
          </th>
          <td>{doubt}</td>
          <td>{offline_doubt}</td>
          <td className="font-weight-bold">
            {Math.abs(doubt - offline_doubt)}
          </td>
        </tr>
        <tr>
          <th scope="row" className="text-danger">
            {i('cancel')} Exclude
          </th>
          <td>{negative}</td>
          <td>{offline_negative}</td>
          <td className="font-weight-bold">
            {Math.abs(negative - offline_negative)}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const CannotReview = () => <React.Fragment></React.Fragment>

export default function ProjectReview() {
  const { projectId } = useParams()
  const { loading, error, refresh, data: project } = useApi(
    ...apiRoute.projects.getById(projectId)
  )
  const [counts, setNumPapers] = useState({})
  if (loading) {
    return (
      <div className="mx-auto d-flex justify-content-center">
        <LoadingSpinner />
      </div>
    )
  }
  if (error) {
    return <ApiError error={error} refresh={refresh} />
  }
  const { numPapers, numOffline } = counts
  const reviewable =
    numPapers == null ||
    (numOffline > numPapers / 2 && project.status === 'finished')

  if (!reviewable) {
    return (
      <React.Fragment>
        <div className="container">
          <div className="d-flex">
            <Link
              className="text-muted small mb-3 d-inline-block"
              to={`/projects/${projectId}`}
            >
              <EN>← Back to project overview</EN>
              <NL>← Terug naar projectoverzicht</NL>
            </Link>
          </div>
          <div className="d-flex align-items-center mb-3">
            <h1 className="mr-4">
              {project.name} <span className="text-primary">Review</span>
            </h1>
          </div>
          <div className="text-danger">
            <h3>
              <EN>
                This project does not have any labels from another source to
                compare and review.
              </EN>
              <NL>
                Dit project lijkt geen andere labels te hebben om te
                vergelijken.
              </NL>
            </h3>
            <p>
              <EN>
                In order to review a project, the original project RIS file must
                have the "LB" field filled for most of the papers.
              </EN>
              <NL>
                Om een project te reviewen moet het "LB" veld in het originele
                RIS bestand bij de meeste papers ingevuld zijn.
              </NL>
            </p>
          </div>
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <div className="container">
        <div className="d-flex">
          <Link
            className="text-muted small mb-3 d-inline-block"
            to={`/projects/${projectId}`}
          >
            <EN>← Back to project overview</EN>
            <NL>← Terug naar projectoverzicht</NL>
          </Link>
        </div>
        <div className="d-flex align-items-center mb-3">
          <h1 className="mr-4">
            {project.name} <span className="text-primary">Review</span>
          </h1>
        </div>
        <div className="d-flex flex-flow-row">
          {reviewable ? <ReviewLabelCounts {...counts} /> : <CannotReview />}
        </div>
        <div className="my-3" />
        <h5>
          <EN>The following papers have different labels</EN>
          <NL>De volgende papers hebben verschillende labels</NL>
        </h5>
        <div className="my-3" />
      </div>
      <div className="container">
        <PaperFilter
          projectTags={project.tags}
          projectStatus={project.status}
        />
      </div>
      <div className="container-fluid mt-4">
        <Papers
          projectId={projectId}
          tags={mapTags(project.tags)}
          setNumPapers={setNumPapers}
          review
        />
      </div>
    </React.Fragment>
  )
}
