import * as React from 'react'
import ReactLoading from 'react-loading'
// import logo from '../assets/Logo@2x.png'

export const LoadingSpinner = props => (
  <ReactLoading type="spin" color="#009e75" {...props} />
)

export const LoadingPage = ({ message }) => (
  <div className="vw-100 vh-100 d-flex justify-content-center align-items-center flex-column">
    <div className="row justify-content-center mb-4">
      {/* <div className="col-md-6 col-lg-4">
        <img className="logo w-100" src={logo} alt="WaterScape" />
      </div> */}
    </div>
    <LoadingSpinner />
    {message != null ? <h3 className="mt-4">{message}</h3> : null}
    <div className="h-25" />
  </div>
)

export const LoadingOverlay = () => (
  <div
    className="d-flex justify-content-center align-items-center flex-column"
    style={{
      position: 'absolute',
      zIndex: '1000',
      backgroundColor: '#cde7e1',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    }}
  >
    <LoadingSpinner />
  </div>
)
