import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { LabelNextButton } from '../components/LabelNextButton'
import { NL, EN } from '../components/Language'
import { useApi, apiRoute } from '../api'
import { ApiError } from '../components/ApiError'
import { LoadingSpinner } from '../components'

const ProjectStatus = ({ status }) => {
  switch (status) {
    case 'import':
      return (
        <React.Fragment>
          <EN>Importing</EN>
          <NL>Aan het importeren</NL>
        </React.Fragment>
      )
    case 'finished':
      return (
        <React.Fragment>
          <EN>Finished AutoLabeling!</EN>
          <NL>Klaar met automatisch labelen!</NL>
        </React.Fragment>
      )
    default:
      return (
        <React.Fragment>
          <EN>Ready to label</EN>
          <NL>Klaar om te labelen</NL>
        </React.Fragment>
      )
  }
}

const ProjectRow = ({ project }) => {
  const history = useHistory()
  const url = `/projects/${project.id}`
  return (
    <tr
      className="project"
      onClick={() => history.push(url)}
      style={{ cursor: 'pointer' }}
    >
      <th scope="row">{project.id}</th>
      <td>
        <Link to={url}>{project.name}</Link>
      </td>
      <td>
        <ProjectStatus status={project.status} />
      </td>
      <td>{project.user_username}</td>
      <td>
        {project.status !== 'import' ? (
          <div className="d-flex justify-content-end">
            <LabelNextButton project={project} className="btn-sm btn-primary">
              <EN>Label Papers →</EN>
              <NL>Label Papers →</NL>
            </LabelNextButton>
            <Link
              className="btn btn-sm btn-primary-light rounded-pill ml-2"
              to={url}
            >
              <EN>Go to project →</EN>
              <NL>Bekijk project →</NL>
            </Link>
          </div>
        ) : null}
      </td>
    </tr>
  )
}

export default function Projects() {
  const { loading, error, refresh, data } = useApi(...apiRoute.projects.find())
  if (error) {
    return <ApiError error={error} refresh={refresh} />
  }
  if (loading) {
    return (
      <div className="mx-auto d-flex justify-content-center">
        <LoadingSpinner />
      </div>
    )
  }
  const { projects } = data
  return (
    <div className="container-fluid-max">
      <div className="d-flex align-items-center">
        <h1>
          <EN>Projects</EN>
          <NL>Projecten</NL>
        </h1>
        <Link
          className="btn btn-primary ml-4 rounded-pill d-flex align-items-center"
          to="/projects/new"
        >
          <i className="material-icons mr-1" style={{ fontSize: '1.3em' }}>
            add
          </i>
          <EN>Upload a project</EN>
          <NL>Project uploaden</NL>
        </Link>
      </div>
      <div className="my-3" />
      <table className="table table-hover">
        <thead className="small sticky-top">
          <tr>
            <th scope="col">#</th>
            <th scope="col">
              <EN>Name</EN>
              <NL>Naam</NL>
            </th>
            <th scope="col">Status</th>
            <th scope="col">
              <EN>By</EN>
              <NL>Door</NL>
            </th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <LoadingSpinner />
          ) : (
            projects &&
            projects.map(project => (
              <ProjectRow project={project} key={project.id} />
            ))
          )}
        </tbody>
      </table>
    </div>
  )
}
