import React, { useState, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Text from 'react-textarea-autosize'
import { LoadingSpinner } from '../components/Loading'
import { NL, EN } from '../components/Language'
import { apiRoute, withToken } from '../api'
import { useAuth0 } from '@auth0/auth0-react'
import { apiError } from '../components/Notifications'

// TODO file upload
export default function NewProject() {
  const history = useHistory()
  const { getAccessTokenSilently } = useAuth0()
  const [project_name, setName] = useState('')
  const [search_terms, setSearchTerms] = useState('')
  const [tags, setTags] = useState('')
  const [uploading, setUploading] = useState(false)
  const formRef = useRef(null)
  const risFileRef = useRef(null)
  const uploadProject = async ris_file => {
    setUploading(true)
    const [url, options] = apiRoute.projects.create({
      project_name,
      search_terms,
      tags,
      ris_file
    })
    try {
      const token = await getAccessTokenSilently()
      const response = await fetch(url, withToken(options, token))
      await response.json()
      history.push('/projects')
    } catch (error) {
      apiError({ error, url, options })
      setUploading(false)
    }
  }
  return (
    <div className="container-fluid-max">
      <Link className="text-muted small mb-3 d-inline-block" to="/">
        <EN>← Back to all projects</EN>
        <NL>← Terug naar alle projecten</NL>
      </Link>
      <h1 className="mb-4">
        <EN>Upload a new project</EN>
        <NL>Nieuw Project Uploaden</NL>
      </h1>
      <form ref={formRef} className="form" style={{ minWidth: '400px' }}>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label" htmlFor="inputRisFile">
            <EN>RIS file</EN>
            <NL>RIS Bestand</NL>
            <sup className="text-danger">*</sup>
          </label>
          <div className="col-sm-10">
            <input
              id="inputRisFile"
              className="form-control"
              type="file"
              ref={risFileRef}
              required
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label" htmlFor="inputTitle">
            <EN>Project name</EN>
            <NL>Projectnaam</NL>
            <sup className="text-danger">*</sup>
          </label>
          <div className="col-sm-10">
            <input
              id="inputTitle"
              className="form-control"
              type="text"
              onChange={event => setName(event.target.value)}
              required
            />
          </div>
          <div className="invalid-feedback">Vul de projectnaam in a.u.b.</div>
        </div>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label" htmlFor="inputSearchTerms">
            <EN>Search terms</EN>
            <NL>Zoektermen</NL>
            <sup className="text-danger">*</sup>
          </label>
          <div className="col-sm-10">
            <Text
              id="inputSearchTerms"
              className="form-control"
              onChange={event => setSearchTerms(event.target.value)}
              maxRows={15}
              required
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label" htmlFor="inputTags">
            Tags
          </label>
          <div className="col-sm-10">
            <input
              id="inputTags"
              className="form-control"
              type="text"
              onChange={event => setTags(event.target.value)}
              aria-describedby="emailHelp"
            />
            <small id="emailHelp" className="form-text text-muted">
              <EN>Separate tags with a comma.</EN>
              <NL>Scheid de tags met een komma.</NL>
            </small>
          </div>
        </div>

        <button
          type="submit"
          className={`btn btn-primary rounded-pill d-flex align-items-center ${
            uploading ? 'disabled' : ''
          }`}
          disabled={uploading}
          onClick={async event => {
            event.preventDefault()
            event.stopPropagation()
            if (uploading) return
            const form = formRef.current
            const valid = form.checkValidity()
            if (valid) {
              const ris_file = risFileRef.current.files[0]
              uploadProject(ris_file)
            }
            form.classList.add('was-validated')
          }}
        >
          {uploading ? (
            <span className="mr-2" style={{ marginTop: '-10px' }}>
              <LoadingSpinner height="1rem" width="1rem" color="#fff" />
            </span>
          ) : null}{' '}
          <EN>Upload new project</EN>
          <NL>Uploaden</NL>
        </button>
        <small className="form-text text-muted">
          <EN>This can take a while</EN>
          <NL>Dit kan even duren</NL>
        </small>
      </form>
    </div>
  )
}
