import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Modal, Button } from 'react-bootstrap'
import { NL, EN } from './Language'

export const ContinueModal = ({ show, labelNext, goBack, handleClose }) => (
  <Modal show={show} scrollable onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>
        <EN>Project is finished</EN>
        <NL>Project is klaar</NL>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <EN>
        The AI is ready. You do not have to keep labeling, but you can if you
        want to of course.
      </EN>
      <NL>
        De AI is klaar met labelen. Je kunt doorgaan natuurlijk, maar dat is
        niet nodig.
      </NL>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={goBack}>
        <EN>Stop labeling</EN>
        <NL>Stop met labelen</NL>
      </Button>
      <Button variant="primary" onClick={labelNext}>
        <EN>Continue labeling →</EN>
        <NL>Doorgaan →</NL>
      </Button>
    </Modal.Footer>
  </Modal>
)

export const LabelNextButton = ({
  project,
  className = '',
  children,
  disabled = false
}) => {
  const history = useHistory()
  const [modalShow, setModalShow] = useState(false)
  return (
    <React.Fragment>
      <Button
        disabled={disabled}
        className={`btn btn-primary rounded-pill d-flex align-items-center ${className} ${
          disabled ? 'disabled' : ''
        }`}
        onClick={async event => {
          if (disabled) return
          event.preventDefault()
          event.stopPropagation()
          if (project.status === 'finished') return setModalShow(true)
          else return history.push(`/projects/${project.id}/papers/next`)
        }}
      >
        {children}
      </Button>
      <ContinueModal
        show={modalShow}
        goBack={() => history.push(`/projects/${project.id}`)}
        labelNext={() => history.push(`/projects/${project.id}/papers/next`)}
        handleClose={() => setModalShow(false)}
      />
    </React.Fragment>
  )
}
