import React from 'react'
import './Notifications.scss'
import { toast, Slide } from 'react-toastify'
import { NL, EN } from './Language'

const CloseButton = ({ closeToast, className }) => (
  <i
    className={`material-icons close-button ${className}`}
    onClick={closeToast}
  >
    close
  </i>
)

const DefaultError = () => (
  <React.Fragment>
    <EN>Something went wrong :(</EN>
    <NL>Er is iets fout gegaan :(</NL>
  </React.Fragment>
)

export const configureNotifications = () =>
  toast.configure({
    transition: Slide,
    autoClose: false,
    toastClassName: 'default-toast',
    closeButton: <CloseButton />
  })

export const apiError = async ({ error: response, url, options }) => {
  let error = response
  if (typeof response.json === 'function') {
    error = await response.json()
  }
  console.error(error, url, options)
  const defaultError = () => toast.error(DefaultError)
  const endpoint = url.split('/')[1]
  switch (endpoint) {
    case 'login':
      return // handled by login form
    case 'token': {
      return toast.error(
        <React.Fragment>
          <EN>You were logged out automatically. Please log in again.</EN>
          <NL>Je bent automatisch uitgelogd. Log weer in a.u.b.</NL>
        </React.Fragment>
      )
    }
    case 'projects': {
      if (response.status === 423) {
        return toast.error(
          <div>
            <EN>
              Active labeling is only possible once the project is imported.
              <br />
              (That can take a while.)
            </EN>
            <NL>
              Actief labelen kan nog niet zolang het project aan het importeren
              is.
              <br />
              (Dat kan enige uren duren)
            </NL>
          </div>
        )
      }
      switch (error.message) {
        case 'project not found':
          return toast.error(
            <React.Fragment>
              <EN>Project not found</EN>
              <NL>Project niet gevonden</NL>
            </React.Fragment>
          )
        case 'paper not found':
          return toast.error(
            <React.Fragment>
              <EN>Paper not found</EN>
              <NL>Paper niet gevonden</NL>
            </React.Fragment>
          )
        case 'invalid file type':
          return toast.error(
            <React.Fragment>
              <EN>This file type is not supported. Upload a RIS file.</EN>
              <NL>
                Dit bestandstype is niet ondersteund. Upload een correct
                RIS-bestand.
              </NL>
            </React.Fragment>
          )
        default:
          return defaultError()
      }
    }
    default:
      return defaultError()
  }
}
