import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import LoginButton from './LoginButton'

export const ApiError = ({ error, refresh }) => {
  const { getAccessTokenWithPopup } = useAuth0()
  const getTokenAndTryAgain = async () => {
    await getAccessTokenWithPopup()
    refresh()
  }
  if (error) {
    if (error.status === 404) {
      return (
        <div className="container">
          <div className="alert alert-warning">
            <h1 className="my-3">404</h1>
            <p className="lead">Cannot find what you are looking for.</p>
          </div>
        </div>
      )
    }
    if (error.error === 'login_required') {
      return <LoginButton />
    }
    if (error.error === 'consent_required') {
      return (
        <button onClick={getTokenAndTryAgain}>Consent to reading users</button>
      )
    }
    return (
      <div className="container">
        <div className="alert alert-danger">{error.message}</div>
      </div>
    )
  }
}
