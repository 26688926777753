import React from 'react'
import { LoginButton } from '../components/'

export default function Login() {
  return (
    <div className="vh-100 vw-100 d-flex flex-column justify-content-center align-items-center">
      <div style={{ maxWidth: '360px' }} className="p-4">
        <img
          src="/selectical_logo@2x.png"
          title="Selectical"
          className="img-fluid"
          alt="Selectical"
        />
      </div>
      <LoginButton />
      <div className="my-5" />
    </div>
  )
}
