import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { Link, useParams, useHistory, useLocation } from 'react-router-dom'
import Fuse from 'fuse.js'
import Labels from './Labels'
import { LoadingSpinner } from './Loading'
import { NL, EN } from './Language'
import { renderTags } from './Tags'
import { filtersFromQuery } from './PaperFilter'
import { apiRoute, fixBody, useApi, withToken } from '../api'
import { useAuth0 } from '@auth0/auth0-react'
import { ApiError } from './ApiError'

const updateQuery = (queryString, key, value) => {
  const query = new URLSearchParams(queryString)
  query.set(key, value)
  return '?' + query.toString()
}

const AuthorShort = ({ authors }) => {
  if (authors == null || authors.length === 0) return ''
  return (
    <div
      style={{
        maxWidth: '12em',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}
    >
      {authors[0]}
      {authors.length > 1 ? ' e.a.' : ''}
    </div>
  )
}

const PaperRow = ({ paper, tags }) => {
  const history = useHistory()
  const { getAccessTokenSilently } = useAuth0()
  const { pathname } = useLocation()
  const { projectId } = useParams()
  const [label, setLabel] = useState(paper.label)
  // We are in review mode when the url ends in `/review` or `/review/`
  const review = pathname.endsWith('/review') || pathname.endsWith('/review/')
  const url = `/projects/${projectId}${review ? '/review' : ''}/papers/${
    paper.id
  }`
  const updateLabel = paperId => async label => {
    const token = await getAccessTokenSilently()
    const [url, options] = apiRoute.papers.label(projectId, paperId, label)
    const response = await fetch(url, fixBody(withToken(options, token)))
    const { label: result } = await response.json()
    setLabel(result)
  }

  return (
    <tr
      className="paper"
      onClick={() => history.push(url)}
      style={{ cursor: 'pointer' }}
    >
      <td>
        <Link to={url}>
          {paper.title || (
            <span className="text-muted small">
              <EN>No title</EN>
              <NL>Geen titel</NL>
            </span>
          )}
        </Link>
      </td>
      <td>{paper.year}</td>
      <td title={paper.journal}>
        <div
          style={{
            maxWidth: '12em',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {paper.journal}
        </div>
      </td>
      <td title={(paper.authors || []).join('; ')}>
        <AuthorShort authors={paper.authors} />
      </td>
      <td className="small">{renderTags(_.map(paper.tags, 'id'), tags)}</td>
      <td>
        <Labels label={label} setLabel={updateLabel(paper.id)} />
      </td>
      {review ? (
        <td>
          <Labels label={paper.offline_label} />
        </td>
      ) : null}
    </tr>
  )
}

const PageNumber = ({ i, disabled, setPage, children, nextPage }) => {
  const location = useLocation()
  const search = updateQuery(location.search, 'page', nextPage)
  let to = { ...location, search }
  return (
    <li className={`page-item ${disabled ? 'disabled' : ''}`}>
      <Link
        className="page-link"
        to={disabled ? () => {} : to}
        style={{ cursor: 'pointer' }}
        aria-disabled={disabled ? 'true' : 'false'}
        tabIndex={i}
      >
        {children != null ? children : i + 1}
      </Link>
    </li>
  )
}

const PapersPaginated = ({ papers, tags }) => {
  const { pathname } = useLocation()

  // We are in review mode when the url ends in `/review` or `/review/`
  const review = pathname.endsWith('/review') || pathname.endsWith('/review/')

  let page = Number(new URLSearchParams(useLocation().search).get('page'))
  if (!_.isFinite(page) || page < 1) page = 1
  const pageSize = 25
  const maxPage = Math.ceil(papers.length / pageSize)
  return (
    <React.Fragment>
      <table className="table table-hover">
        <thead className="small">
          <tr>
            <th scope="col">
              <EN>Title</EN>
              <NL>Titel</NL>
            </th>
            <th scope="col">
              <EN>Year</EN>
              <NL>Jaar</NL>
            </th>
            <th scope="col">
              <EN>Journal</EN>
              <NL>Tijdschrift</NL>
            </th>
            <th scope="col">
              <EN>Authors</EN>
              <NL>Auteurs</NL>
            </th>
            <th scope="col">Tags</th>
            {!review ? (
              <th scope="col">Label</th>
            ) : (
              <th scope="col">
                <EN>Labeled here</EN>
                <NL>Hier gelabeled</NL>
              </th>
            )}
            {review ? (
              <th scope="col">
                <EN>Labeled elsewhere</EN>
                <NL>Elders gelabeld</NL>
              </th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {papers.slice((page - 1) * pageSize, page * pageSize).map(paper => (
            <PaperRow paper={paper} tags={tags} key={paper.id} />
          ))}
        </tbody>
      </table>
      <nav aria-label="Pagina-navigatie" className="fixed-bottom">
        <ul className="pagination justify-content-center">
          <PageNumber i={-1} disabled={page === 1} nextPage={page - 1}>
            <EN>← Previous</EN>
            <NL>← Vorige</NL>
          </PageNumber>
          <li className="page-item disabled">
            <div className="page-link">
              <EN>
                Page {page} of {maxPage}
              </EN>
              <NL>
                Pagina {page} van {maxPage}
              </NL>
            </div>
          </li>
          <PageNumber
            i={maxPage}
            disabled={page >= maxPage}
            nextPage={page + 1}
          >
            <EN>Next →</EN>
            <NL>Volgende →</NL>
          </PageNumber>
        </ul>
      </nav>
    </React.Fragment>
  )
}

const FilteredPapers = ({ papers, tags, filters, projectId }) => {
  const [filteredPapers, setFiltered] = useState(papers)
  const [fuse, setFuse] = useState({ search: () => papers })
  useEffect(() => {
    let result = papers
    if (filters.noAbstract) {
      result = result.filter(p => p.problem === 'no_abstract')
    }
    if (filters.noModelScore) {
      result = result.filter(p => p.model_score == null)
    }
    if (filters.autoExclude) {
      result = result.filter(p => p.label == null)
    }
    if (filters.toReview) {
      result = result.filter(p => p.label !== p.offline_label)
    }
    if (filters.labels.length > 0) {
      const allowed = new Set(filters.labels)
      result = result.filter(p => allowed.has(p.label))
    }
    if (filters.tags.length > 0) {
      filters.tags.forEach(tag => {
        result = result.filter(p => {
          return new Set(p.tags.map(t => t.id)).has(tag)
        })
      })
    }
    setFiltered(result)
  }, [papers, filters])
  useEffect(() => {
    const options = {
      tokenize: true,
      findAllMatches: true,
      threshold: 0.4,
      location: 10,
      distance: 1000,
      maxPatternLength: 32,
      minMatchCharLength: 0,
      keys: ['title', 'concatAuthors', 'journal']
    }
    const list = filteredPapers.map(p => ({
      ...p,
      concatAuthors: p.authors != null ? p.authors.join('; ') : ''
    }))
    setFuse(new Fuse(list, options))
  }, [filteredPapers])
  const result =
    filters.search != null ? fuse.search(filters.search) : filteredPapers
  return <PapersPaginated papers={result} tags={tags} />
}

export const Papers = ({ projectId, tags, setNumPapers, review = false }) => {
  const filters = {
    ...filtersFromQuery(useLocation().search),
    toReview: review
  }
  const { loading, error, refresh, data = {} } = useApi(
    ...apiRoute.papers.findByProjectId(projectId)
  )
  const [numPapers, papers] = useMemo(() => {
    if (data != null) return [data.num_papers, data.papers]
    return [null, null]
  }, [data])
  useEffect(() => {
    if (setNumPapers != null && numPapers != null) {
      setNumPapers({
        numPapers,
        ..._.countBy(papers, 'label'),

        // To know if the project can be reviewed, we count offline_labels
        numOffline: papers.filter(d => d.offline_label != null).length,

        // In review mode we also count the offline_labels
        ...(review
          ? _.mapKeys(
              _.countBy(papers, 'offline_label'),
              (v, k) => `offline_${k}`
            )
          : {})
      })
    }
  }, [numPapers, setNumPapers, papers, review])
  if (loading) {
    return (
      <div className="mx-auto d-flex justify-content-center">
        <LoadingSpinner />
      </div>
    )
  }
  if (error) {
    return <ApiError error={error} refresh={refresh} />
  }

  return (
    <FilteredPapers
      papers={papers}
      key={projectId}
      tags={tags}
      filters={filters}
    />
  )
}
