import _ from 'lodash'
import { saveAs as saveAsFile } from 'file-saver'
import { apiError } from './components/Notifications'

import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

export const withToken = (fetchOptions, accessToken) => {
  return {
    ...fetchOptions,
    headers: {
      ...fetchOptions.headers,
      // Add the Authorization header to the existing headers
      Authorization: `Bearer ${accessToken}`
    }
  }
}

export const fixBody = options => {
  const { body, ...fetchOptions } = options
  fetchOptions.headers = {
    'Content-Type': 'application/json',
    ...fetchOptions.headers
  }
  if (body != null && body instanceof FormData) {
    fetchOptions.body = body
    // Fetch flips its shit when you define content-type
    delete fetchOptions.headers['Content-Type']
  } else if (body != null && _.isObject(body)) {
    fetchOptions.body = JSON.stringify(body)
  }
  return fetchOptions
}

export const useApi = (url, options = {}) => {
  const { getAccessTokenSilently } = useAuth0()
  const [state, setState] = useState({
    error: null,
    loading: true,
    data: null
  })
  const [refreshIndex, setRefreshIndex] = useState(0)

  useEffect(() => {
    async function apiUsage() {
      try {
        setState({
          ...state,
          loading: true
        })
        const accessToken = await getAccessTokenSilently()
        const fetchOptions = fixBody(withToken(options, accessToken))
        const res = await fetch(url, fetchOptions)
        if (!res.ok) {
          const error = new Error(res.statusText)
          error.status = res.status
          throw error
        }
        let data
        if (options.blob) {
          data = await res.blob()
        } else {
          data = await res.json()
        }
        setState({
          ...state,
          data,
          error: null,
          loading: false
        })
      } catch (error) {
        apiError({ url, options, error })
        setState({
          ...state,
          error,
          loading: false
        })
      }
    }
    apiUsage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshIndex])

  return {
    ...state,
    refresh: () => setRefreshIndex(refreshIndex + 1)
  }
}

export const saveAs = filename => blob => {
  saveAsFile(blob, filename)
}

class ApiRoute {
  host =
    window.apiHost !== 'REPLACE_API_HOST'
      ? window.apiHost
      : process.env.REACT_APP_API_HOST || 'http://127.0.0.1:8000'

  projects = {
    find: () => [this.host + '/projects/'],
    getById: project_id => [this.host + `/projects/${project_id}`],
    create: ({ project_name, ris_file, search_terms, tags } = {}) => {
      if (
        _.isNil(project_name) ||
        _.isNil(ris_file) ||
        !(ris_file instanceof File)
      ) {
        throw new Error('Required fields not provided')
      }
      const url = this.host + '/projects/'
      const method = 'POST'
      const headers = {}
      const body = new FormData()
      body.append('project_name', project_name)
      body.append('ris_file', ris_file)
      if (tags != null && tags !== '') {
        tags
          .split(',')
          .map(tag => tag.trim())
          .forEach(tag => {
            body.append('tags', tag)
          })
      }
      if (search_terms != null) {
        body.append('search_terms', search_terms)
      }
      return [url, { headers, method, body }]
    },
    export: (project_id, file_type) => {
      const url =
        this.host + `/projects/${project_id}/export?file_type=${file_type}`
      return [url, { blob: true }]
    }
  }

  papers = {
    findByProjectId: project_id => [
      this.host + `/projects/${project_id}/papers`
    ],
    getById: (project_id, paper_id) => [
      this.host + `/projects/${project_id}/papers/${paper_id}`
    ],
    getNext: project_id => [this.host + `/projects/${project_id}/next_paper`],
    label: (project_id, paper_id, label) => {
      const url = this.host + `/projects/${project_id}/papers/${paper_id}`
      const method = 'PATCH'
      const body = { project_id, paper_id, label }
      return [url, { method, body }]
    },
    tag: (project_id, paper_id, tags) => {
      const url = this.host + `/projects/${project_id}/papers/${paper_id}`
      const method = 'PATCH'
      const body = { project_id, paper_id, tags }
      return [url, { method, body }]
    }
  }
}

export const apiRoute = new ApiRoute()
