import React, { useState } from 'react'
import _ from 'lodash'
import { useLocation, useHistory } from 'react-router-dom'
import { Button, Modal, Form } from 'react-bootstrap'

export const filtersFromQuery = queryString => {
  const query = new URLSearchParams(queryString)
  const tags = query.getAll('tags').map(t => Number(t))
  const labels = query.getAll('labels')
  const noAbstract = query.get('noAbstract') === 'true'
  const noModelScore = query.get('noModelScore') === 'true'
  const autoExclude = query.get('autoExclude') === 'true'
  const search = query.get('search')
  return { tags, labels, noAbstract, noModelScore, autoExclude, search }
}

const resetFilters = queryString => {
  let query = new URLSearchParams(queryString)
  query.delete('tags')
  query.delete('labels')
  query.delete('noAbstract')
  query.delete('noModelScore')
  query.delete('autoExclude')
  return '?' + query.toString()
}

const resetParam = (queryString, param) => {
  let query = new URLSearchParams(queryString)
  query.delete(param)
  return '?' + query.toString()
}

const updateQuery = (queryString, key, value) => {
  let query = new URLSearchParams(queryString)
  if (_.isArray(value)) {
    value.forEach(v => query.append(key, String(v)))
  } else {
    query.set(key, String(value))
  }
  return '?' + query.toString()
}

const FilterButton = ({ amount, onClick }) => (
  <Button variant="primary" className="rounded-pill" onClick={onClick}>
    <i
      className="material-icons"
      style={{ fontSize: '1rem', transform: 'translateY(2px)' }}
    >
      filter_list
    </i>{' '}
    {amount > 0 ? (
      <span>
        Filters{' '}
        <span className="badge badge-pill rounded-pill badge-white">
          {amount}
        </span>
      </span>
    ) : (
      'Filter papers'
    )}
  </Button>
)

const FilterModal = ({
  show,
  handleClose,
  filters = {},
  projectTags,
  projectStatus
}) => {
  const [tags, setTags] = useState(new Set(filters.tags))
  const [labels, setLabels] = useState(new Set(filters.labels))
  const [noAbstract, setAbstract] = useState(filters.noAbstract)
  const [noModelScore, setModelScore] = useState(filters.noModelScore)
  const [autoExclude, setAutoExclude] = useState(filters.autoExclude)
  const toggle = (set, value) => {
    if (set.has(value)) {
      const result = new Set([...set])
      result.delete(value)
      return result
    }
    return new Set([...set, value])
  }
  return (
    <Modal show={show} scrollable onHide={() => {}}>
      <Modal.Header>
        <Modal.Title>Filter papers</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Show only papers...</h5>
        <Form.Check
          type="checkbox"
          label={
            <span>
              ...excluded by the algorithm
              <span className="ml-2 small">
                (only when project is finished)
              </span>
            </span>
          }
          onChange={event => setAutoExclude(event.target.checked)}
          checked={autoExclude}
          disabled={projectStatus !== 'finished'}
        />
        <Form.Check
          type="checkbox"
          label="...without an abstract"
          onChange={event => setAbstract(event.target.checked)}
          checked={noAbstract}
        />
        <Form.Check
          type="checkbox"
          label="...without a model score"
          onChange={event => setModelScore(event.target.checked)}
          checked={noModelScore}
        />
        <h5 className="mt-4">Show only papers labeled</h5>
        <Form.Check
          type="checkbox"
          label="Include"
          onChange={() => setLabels(toggle(labels, 'positive'))}
          checked={labels.has('positive')}
        />
        <Form.Check
          type="checkbox"
          label="Doubt"
          onChange={() => setLabels(toggle(labels, 'doubt'))}
          checked={labels.has('doubt')}
        />
        <Form.Check
          type="checkbox"
          label="Exclude"
          onChange={() => setLabels(toggle(labels, 'negative'))}
          checked={labels.has('negative')}
        />
        {projectTags != null && projectTags.length > 0 ? (
          <div>
            <h5 className="mt-4">Show only papers tagged</h5>
            {projectTags.map(tag => (
              <Form.Check
                key={tag.id}
                type="checkbox"
                label={tag.value}
                onChange={() => setTags(toggle(tags, tag.id))}
                checked={tags.has(tag.id)}
              />
            ))}
          </div>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => handleClose()}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() =>
            handleClose({
              tags: [...tags],
              labels: [...labels],
              noAbstract,
              noModelScore,
              autoExclude
            })
          }
        >
          Filter →
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export function PaperFilter({ projectTags, projectStatus }) {
  const [show, setShow] = useState(false)
  const location = useLocation()
  const history = useHistory()
  const filters = filtersFromQuery(location.search)
  const [searchString, setSearch] = useState(filters.search || '')
  const amount = _.size(
    _.filter(filters, (value, key) => {
      if (key === 'search') return false // we don't count those
      if (_.isArray(value) && _.isEmpty(value)) return false
      else if (_.isNil(value) || !value) return false
      return true
    })
  )
  const handleClose = newFilters => {
    setShow(false)
    if (newFilters) {
      let query = resetFilters(location.search)
      query = resetParam(query, 'page')
      for (const key in newFilters) {
        if (newFilters[key]) {
          query = updateQuery(query, key, newFilters[key])
        }
      }
      history.push({
        ...location,
        search: query
      })
    }
  }
  const handleShow = () => setShow(true)
  const handleSearch = event => {
    event.preventDefault()
    event.stopPropagation()
    let search = resetParam(location.search, 'page')
    if (searchString == null || searchString === '') {
      search = resetParam(search, 'search')
    } else {
      search = updateQuery(search, 'search', searchString)
    }
    history.push({
      ...location,
      search
    })
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleSearch}>
        <Form.Row>
          <div className="col">
            <Form.Control
              type="text"
              placeholder="Search for papers, authors, journals"
              value={searchString}
              className="rounded-pill"
              onChange={event => setSearch(event.target.value)}
              onBlur={handleSearch}
            />
          </div>
          <div className="col-auto">
            <Button type="submit" className="rounded-pill">
              Search
            </Button>
          </div>
          <div className="col-auto">
            <FilterButton amount={amount} onClick={handleShow} />
          </div>
        </Form.Row>{' '}
      </Form>
      <FilterModal
        show={show}
        handleClose={handleClose}
        filters={filters}
        projectTags={projectTags}
        projectStatus={projectStatus}
      />
    </React.Fragment>
  )
}
