import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { configureNotifications } from './components/Notifications'
import { Auth0Provider } from '@auth0/auth0-react'

configureNotifications()

ReactDOM.render(
  <Auth0Provider
    domain="dev-ecmhaxf8.eu.auth0.com"
    clientId="aO6EAFh48QkJoQXJYdEFqh44NSTuYxKn"
    redirectUri={window.location.origin}
    audience="https://pallas-test.wearelandscape.nl"
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
