import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0()

  return (
    <button
      className="btn btn-primary ml-4 rounded-pill d-flex align-items-center"
      onClick={() => loginWithRedirect()}
    >
      Log In
    </button>
  )
}

export default LoginButton
