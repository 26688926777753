import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Link, useParams, useHistory, useLocation } from 'react-router-dom'
import Labels, { LargeLabel } from '../components/Labels'
import { ContinueModal, LabelNextButton } from '../components/LabelNextButton'
import Tags from '../components/Tags'
import { LoadingSpinner } from '../components/Loading'
import { NL, EN } from '../components/Language'
import { apiRoute, fixBody, useApi, withToken } from '../api'
import { useAuth0 } from '@auth0/auth0-react'
import { ApiError } from '../components/ApiError'
import { apiError } from '../components/Notifications'
import { Button } from 'react-bootstrap'

const Positive = () => (
  <LargeLabel
    style={{ opacity: '1' }}
    label="positive"
    displayLabel="Include"
    current="positive"
    icon="check_circle"
  />
)

const Doubt = () => (
  <LargeLabel
    style={{ opacity: '1' }}
    label="doubt"
    displayLabel="Doubt"
    current="doubt"
    icon="help"
  />
)
const Negative = () => (
  <LargeLabel
    style={{ opacity: '1' }}
    label="negative"
    displayLabel="Exclude"
    current="negative"
    icon="cancel"
  />
)

const BackButton = () => {
  const history = useHistory()
  return (
    <Button
      className={`btn btn-primary rounded-pill d-flex align-items-center`}
      onClick={async event => {
        event.preventDefault()
        event.stopPropagation()
        return history.goBack()
      }}
    >
      <EN>← Previous</EN>
      <NL>← Vorige</NL>
    </Button>
  )
}

const SideBar = ({ paper, project, review = false }) => {
  const history = useHistory()
  const { getAccessTokenSilently } = useAuth0()
  const [label, setLabel] = useState(paper.label)
  const [tags, setTags] = useState(_.map(paper.tags, 'id'))
  const [modalShow, setModalShow] = useState(false)
  useEffect(() => {
    if (!review && paper != null && label !== paper.label) {
      // The user changed the label!
      // Go to the next paper or give a popup if the project is finished
      if (project.status === 'finished') setModalShow(true)
      else history.push(`/projects/${project.id}/papers/next`)
    }
  }, [history, project, paper, label, review])
  const updateLabel = paperId => async label => {
    const token = await getAccessTokenSilently()
    const [url, options] = apiRoute.papers.label(project.id, paperId, label)
    const response = await fetch(url, fixBody(withToken(options, token)))
    const { label: result } = await response.json()
    setLabel(result)
  }
  const updateTag = paperId => async tagId => {
    const currentTags = new Set(tags)
    if (currentTags.has(tagId)) {
      currentTags.delete(tagId)
    } else {
      currentTags.add(tagId)
    }
    const [url, options] = apiRoute.papers.tag(project.id, paperId, [
      ...currentTags
    ])
    try {
      const token = await getAccessTokenSilently()
      const response = await fetch(url, fixBody(withToken(options, token)))
      const { tags: result } = await response.json()
      setTags(_.map(result, 'id'))
    } catch (error) {
      apiError({ error, url, options })
    }
  }
  return (
    <React.Fragment>
      <div
        onKeyPress={event => {
          const key = event.key.toLowerCase()
          if (key === 'i') {
            return updateLabel(paper.id)('positive')
          }
          if (key === 'd') {
            return updateLabel(paper.id)('doubt')
          }
          if (key === 'e') {
            return updateLabel(paper.id)('negative')
          }
        }}
      >
        {_.size(project.tags) > 0 ? (
          <div className="mt-4 mb-4 d-flex flex-wrap align-items-start">
            <div className="font-weight-bold mr-2">Tags:</div>
            <Tags
              currentTags={tags}
              allTags={project.tags}
              setTag={updateTag(paper.id)}
            />
          </div>
        ) : null}
        {review ? (
          <h5 className="text-primary text-center">Labeled here</h5>
        ) : null}
        <Labels label={label} size="large" setLabel={updateLabel(paper.id)} />
        {review ? (
          <div
            className="border-primary py-3"
            style={{
              backgroundColor: 'rgba(0, 85, 151, 0.08)',
              border: '1px solid #eee'
            }}
          >
            <h5 className="text-primary text-center">Labeled elsewhere</h5>
            {paper.offline_label === 'positive' ? <Positive /> : null}
            {paper.offline_label === 'doubt' ? <Doubt /> : null}
            {paper.offline_label === 'negative' ? <Negative /> : null}
          </div>
        ) : null}
        {!review ? (
          <div className="mt-4 d-flex justify-content-center">
            <LabelNextButton project={project} disabled={label == null}>
              <EN>Label next →</EN>
              <NL>Volgende labelen →</NL>
            </LabelNextButton>
          </div>
        ) : null}
      </div>
      <ContinueModal
        show={modalShow}
        goBack={() => history.push(`/projects/${project.id}`)}
        labelNext={() => history.push(`/projects/${project.id}/papers/next`)}
        handleClose={() => setModalShow(false)}
      />
      <div className="mt-4 d-flex justify-content-center">
        <BackButton />
      </div>
    </React.Fragment>
  )
}

export default function PaperDetail() {
  const { projectId, paperId } = useParams()
  const { pathname } = useLocation()
  const {
    loading: loadingProject,
    error: errorProject,
    refresh: refreshProject,
    data: project
  } = useApi(...apiRoute.projects.getById(projectId))
  const {
    loading: loadingPaper,
    error: errorPaper,
    refresh: refreshPaper,
    data: paper
  } = useApi(...apiRoute.papers.getById(projectId, paperId))
  useEffect(() => {
    if (!loadingProject && !errorProject && project != null) {
      if (String(projectId) !== String(project.id)) {
        refreshProject()
      }
    }
  }, [errorProject, loadingProject, project, projectId, refreshProject])
  useEffect(() => {
    if (!loadingPaper && !errorPaper && paper != null) {
      if (String(paperId) !== String(paper.id)) {
        refreshPaper()
      }
    }
  }, [errorPaper, loadingPaper, paper, paperId, refreshPaper])
  if (errorProject) {
    return <ApiError error={errorProject} refresh={refreshProject} />
  }
  if (errorPaper) {
    return <ApiError error={errorPaper} refresh={refreshPaper} />
  }
  if (loadingProject || (loadingPaper && paper == null)) {
    return (
      <div className="mx-auto d-flex justify-content-center">
        <LoadingSpinner />
      </div>
    )
  }
  // We are in review mode when the url contains `/review/`
  const review = pathname.match(/(\/review\/)/g)

  return (
    <div className="container-fluid-max" style={{ marginTop: '-1.8rem' }}>
      <Link
        to={`/projects/${projectId}${review ? '/review' : ''}`}
        className="text-muted"
      >
        ← {project.name} {review ? 'Review' : ''}
      </Link>
      <div className="row align-items-start">
        <div className="col" style={{ maxWidth: '56rem' }}>
          <h2 className="my-3">{paper.title}</h2>
          {paper.authors != null ? (
            <h5 className="text-primary">{paper.authors.join('; ')}</h5>
          ) : null}
          <h5>
            {[
              paper.journal != null ? paper.journal : null,
              paper.year != null ? paper.year : null
            ]
              .filter(d => d != null)
              .join(' – ')}
          </h5>
          <hr className="my-3" />
          <div className="text-justify" style={{ whiteSpace: 'pre-wrap' }}>
            {paper.abstract}
          </div>
          <hr className="my-4" />
          <div className="row">
            <div className="col-auto font-weight-bold">Keywords: </div>
            <div className="col d-flex flex-wrap">
              {paper.keywords != null
                ? paper.keywords.map((term, i) => (
                    <React.Fragment key={i}>
                      <span
                        className="mr-2 mt-1"
                        style={{
                          textDecoration: 'underline',
                          textDecorationColor: '#999'
                        }}
                      >
                        {term}
                        {i < paper.keywords.length - 1 ? ',' : ''}
                      </span>
                    </React.Fragment>
                  ))
                : null}
            </div>
          </div>
        </div>

        <div className="sticky-top p-3" style={{ flex: '0 1 220px' }}>
          {paper != null && project != null ? (
            <SideBar
              paper={paper}
              project={project}
              key={paper.id}
              review={review}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}
