import './styles/shared.scss'
import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useLocation
} from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'

import Login from './views/Login'
import Projects from './views/Projects'
import NextPaper from './views/NextPaper'
import NewProject from './views/NewProject'
import ProjectDetail from './views/ProjectDetail'
import ProjectReview from './views/ProjectReview'
import PaperDetail from './views/PaperDetail'
import LogoutLink from './components/LogoutLink'
import { NL, EN } from './components/Language'
import SelecticalLogo from './assets/selectical_logo@2x.png'

const Routes = () => {
  const { isAuthenticated, isLoading } = useAuth0()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])
  if (isLoading) return null
  if (!isAuthenticated) {
    if (location.pathname !== '/') history.push('/')
    return <Login />
  }
  return (
    <React.Fragment>
      <div style={{ minHeight: '100vh' }} className="d-flex flex-column">
        <header className="mb-5">
          <nav className="navbar navbar-light bg-primary-light px-0">
            <div className="container">
              <Link className="navbar-brand" to="/">
                <img src={SelecticalLogo} height={30} alt="Selectical" />
              </Link>
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <Link to="/">
                    <EN>Projects</EN>
                    <NL>Projecten</NL>
                  </Link>
                </li>
              </ul>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <LogoutLink />
                </li>
              </ul>
            </div>
          </nav>
        </header>
        <Switch>
          <Route exact path="/">
            <Projects />
          </Route>
          <Route exact path="/projects/">
            <Projects />
          </Route>
          <Route path="/projects/:projectId/papers/next">
            <NextPaper />
          </Route>
          <Route path="/projects/new">
            <NewProject />
          </Route>
          <Route path="/projects/:projectId/papers/:paperId">
            <PaperDetail />
          </Route>
          <Route path="/projects/:projectId/review/papers/:paperId">
            <PaperDetail />
          </Route>
          <Route path="/projects/:projectId/review">
            <ProjectReview />
          </Route>
          <Route path="/projects/:projectId">
            <ProjectDetail />
          </Route>
        </Switch>
        <footer className="mt-auto small py-5">
          <div className="container-fluid text-right text-primary">
            © Selectical 2021-{new Date().getFullYear()}
          </div>
        </footer>
      </div>
    </React.Fragment>
  )
}

export default function App() {
  return (
    <Router>
      <Routes />
    </Router>
  )
}
